import React from 'react';
import styled, {keyframes} from "styled-components";
import theme from "styles/style";
import Link from "gatsby-link";

const down = keyframes`
  from {
    transform: translateY(-10px);
  }
  to {
    transform: translateY(0px);
  }
`;

export const ToDown = styled.div`
  position: absolute;
  width: 100%;
  height: 3em;
  bottom: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
  background:linear-gradient(0deg, ${theme.backgroundDarkColor} 0%, ${theme.backgroundDarkColor}  40%, rgba(0,0,0,0) 100%);
  z-index: 2;
  a {
    animation: ${down} 1s ease-in-out infinite ;
  }
`

export const ButtonDown = ({to}) => {
    return (
        <ToDown>
            <Link to={to} >
            <svg xmlns="http://www.w3.org/2000/svg" width="26.828" height="26.828" viewBox="0 0 26.828 26.828">
                <g id="buttonDown" data-name="Grupo 6" transform="translate(-1851.586 -150.586)">
                    <g id="buttonDown__1" data-name="Grupo 4" transform="translate(377 -418)">
                        <line id="Línea_7" data-name="Línea 7" x2="12" y2="12" transform="translate(1476 582)" fill="none" stroke={theme.textColor} strokeLinecap="round" strokeWidth="2"/>
                        <line id="Línea_9" data-name="Línea 9" x1="12" y2="12" transform="translate(1488 582)" fill="none" stroke={theme.textColor}  strokeLinecap="round" strokeWidth="2"/>
                    </g>
                    <g id="buttonDown__2" data-name="Grupo 5" transform="translate(377 -430)">
                        <line id="Línea_7-2" data-name="Línea 7" x2="12" y2="12" transform="translate(1476 582)" fill="none" stroke={theme.textColor}  strokeLinecap="round" strokeWidth="2"/>
                        <line id="Línea_9-2" data-name="Línea 9" x1="12" y2="12" transform="translate(1488 582)" fill="none" stroke={theme.textColor}  strokeLinecap="round" strokeWidth="2"/>
                    </g>
                </g>
            </svg>
            </Link>
        </ToDown>
    );
};

export default ButtonDown;